import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

// Okta
import { login } from '../../pages/App';
import ringIconUrl from 'url:../../images/ring_logo_white.png';

const LandingWrapper = styled.div`
    margin: 8% 0px;
    width: 100%;
    max-width: 800px;
    background-color: #0A405A;
    border-radius: 10px;
    padding: 75px 50px 25px;
    box-shadow: 1px 1px 4px rgba(0,0,0, .2);
    align-self: center;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        font-family: equipextralight;
        font-size: 32px;
    }

    & p {
        font-family: equipextralight;
        font-size: 20px;
    }
`;

const LogoImg = styled.img`
    align-self: center;
    margin: auto;
    width: 150px;
    padding: 15px;
    border-radius: 10px
`;

function OktaLogin() {
    return (
        <LandingWrapper>
            <LogoImg
                src={ringIconUrl}
                title='iconheader'
            />
            <h1>Welcome to CS Order Details!</h1>
            <p>Please Login to access this tool</p>
            <div>
                <Button
                    type='default'
                    size='large'
                    onClick={login}
                >Login</Button>
            </div>
        </LandingWrapper>
    );
}

export default OktaLogin;
