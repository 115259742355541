
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import { OktaAuthReact } from '@amzn/ring-cs-okta-auth-client';
import settings from '../settings.json';
import OauthRedirect from './OauthRedirect';
import 'antd/dist/reset.css';
import '../components/layouts/base.css';
import '../components/layouts/font-face.css';
import { OrderDetailsPage } from './OrderDetails';

// prep this site to use Okta
const { okta: { iss, cid } } = (settings as any)[window.location.host] || settings.default;
export const {
	ContinuousAuthProvider,
	useAuthState,
	useContinuousAuth,
	login,
	logout,
	getHeaders,
} = new OktaAuthReact(iss, cid);

function App() {
	return (
		<Routes>
			<Route path='/' element={<OrderDetailsPage />} />
			<Route path='/oauth' element={<OauthRedirect />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default App;