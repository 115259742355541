import React from 'react';
import PageContent from '../components/layouts/PageContent';
import styled from 'styled-components';

const NotFoundContent = styled.div`
	padding: 25px;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

function NotFoundPage() {
	return (
		<PageContent
			header='CS Tools'
			subheader='404: Not found'
			footer={true}
		>
			<NotFoundContent>
				<h1>NOT FOUND</h1>
				<p>You just hit a route that doesn't exist... the sadness!</p>
			</NotFoundContent>
		</PageContent>
	);
}

export default NotFoundPage;