const config = {
	siteMetadata: {
		title: `Ring CS Tools`,
		description: `A collection of miscellaneous tools and scripts to be used by members of Ring CS and Operations.`,
		author: `ring-cs`,
		resourcesHostProd: "cs-tools.ring.com",
		resourcesHostGamma: "cs-tools.qa.ring.com",
		resourcesHostBeta: "cs-tools.dev.ring.com",
	},
};

export default config;