import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import settings from '../settings.json';

const settingEnv = (settings as any)[window.location.host] || settings.default;
const { api_prefix: apiPrefix } = settingEnv;

const useRedirectUrl = () => {
    const [searchParams] = useSearchParams();
    const redirectUrl = new URL(`${apiPrefix}/auth/redirect`);
    searchParams.forEach((value, key) => redirectUrl.searchParams.append(key, value));
    return redirectUrl.href;
}

function OauthRedirect() {
    const redirectUrl = useRedirectUrl();

    useEffect(() => {
        if (redirectUrl) {
            console.log('redirecting');
            window.open(redirectUrl, '_self');
        }
    }, [redirectUrl]);

    return (
        <div>
            <p>Please click <a href={redirectUrl}>here</a> if you are not redirected in a few seconds</p>
        </div>
    );
}

export default OauthRedirect;