import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Favicon from '../../images/favicon-48x48.png';

import config from '../../config';

function Seo({ description, lang, meta, keywords, title }) {
	const metaDescription = description || config.siteMetadata.description;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			link={[
				{ rel: 'shortcut icon', type: 'image/x-icon', href: `${Favicon}` },
			]}
			title={title}
			titleTemplate={`%s | ${config.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: config.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]
				.concat(
					keywords.length > 0
						? {
							name: `keywords`,
							content: keywords.join(`, `),
						}
						: []
				)
				.concat(meta)}
		/>
	);
}

Seo.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
}

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
}

export default Seo;
